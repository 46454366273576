<template>
  <div class="memorabilia-cont">
    <h2>
      <div class="title-hr"></div>
      <span>版本大事记</span>
      <div class="title-hr"></div>
    </h2>
    
    <van-steps active-color="#1989fa" active-icon="clock-o" direction="vertical" :active="0">
      <!-- 
        版本划分依据：
          数字.0.0 ： 新增功能，修改业务流程。
          0.数字.0 ： 新增供应商，新增页面。
          0.0.数字 ： 修改bug，样式。

        1、依照class名称得语义划分，修改对应文字即可。
        2、不用div的删掉就行。
        3、注意排序，参下。
        <van-step class="node">
          <div class="time">
            <div class="MMDD">07-12</div>
            <div class="hhmm">14:37</div>
          </div>
          <div class="contain">
            <div class="detail">主要内容主要内容主要内容主要内容</div>
            <div class="footer">底部小字</div>
          </div>
        </van-step>
       -->
       <!-- 升序还是降序？ 答：👆 新的在上边，老的在下边 往本行注释下边加内容即可 -->
      
      <!-- <van-step class="node">
        <div class="time">
          <div class="MMDD">11-24</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">新增“机票保险”功能。机票、酒店下单页面新增“价格明细”。优化选择城市长列表的渲染性能，提高页面稳定性。修复城市列表右侧导航的兼容的问题。订单页增加检索目的地和出行人员功能。</div>
          <div class="footer">版本号：1.3.0</div>
        </div>
      </van-step> -->
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">11-27</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">新增机票、酒店下单页面新增“价格明细”。优化选择城市长列表的渲染性能，提高页面稳定性。修复城市列表右侧导航的兼容的问题。订单页增加检索目的地和出行人员功能。</div>
          <div class="footer">版本号：1.2.8</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">11-20</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">优化“选择城市”样式、列表操作、下单动画、完善机建费、订单页提示信息。增加左下方导航功能。修改抢座成功后的跳转逻辑。</div>
          <div class="footer">版本号：1.2.7</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">11-16</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">优化“选择城市”页面默认城市，丰富火车票订单页显示信息。切换申请单后重置默认城市。</div>
          <div class="footer">版本号：1.2.6</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">11-13</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">优化目的地推荐、行程单展示、优化酒店排序以及登录模块。修复按区搜索酒店时的定位偏差，修复酒店选房时日期解析异常。增加保险相关组件。</div>
          <div class="footer">版本号：1.2.5</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">11-10</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">优化搜索页面时间显示，修复酒店无房型的情况，增加对苹果底部安全区的适配。</div>
          <div class="footer">版本号：1.2.4</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">11-05</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">优化机票列表、订单列表的稳定性，减少http成本。</div>
          <div class="footer">版本号：1.2.3</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">10-30</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">调整搜索页面默认出发时间。修改机票订单页的服务商Logo。优化机、火、酒列表页面，保留用户历史行为，更换默认排序方式，增加筛选徽标。</div>
          <div class="footer">版本号：1.2.2</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">10-27</div>
          <div class="hhmm">17:30</div>
        </div>
        <div class="contain">
          <div class="detail">更换搜索机票页面图标，优化行程限额控制，优化切换页面时的稳定性。</div>
          <div class="footer">版本号：1.2.1</div>
        </div>
      </van-step>

      <van-step class="node">
        <div class="time">
          <div class="MMDD">10-20</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">酒店模块接入“差旅壹号”（已接通三家供应商）。</div>
          <div class="footer">版本号：1.2.0</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">09-20</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">合并火车、机票、酒店订单。</div>
          <div class="footer">版本号：1.1.2</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">09-20</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">修复订单状态显示异常的BUG、完善酒店下单自动“分配房间”功能。</div>
          <div class="footer">版本号：1.1.1</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">09-11</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">酒店模块接入“行旅”（已接通两家供应商）。</div>
          <div class="footer">版本号：1.1.0</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">09-04</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">增加行程“超标”、“额度”控制。</div>
          <div class="footer">版本号：1.0.4</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">08-26</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">修复一些已知的问题。增加同机型多服务商比价功能。</div>
          <div class="footer">版本号：1.0.3</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">08-16</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">增加依照“时段”检索机票的功能。</div>
          <div class="footer">版本号：1.0.2</div>
        </div>
      </van-step>
      
      <van-step class="node">
        <div class="time">
          <div class="MMDD">08-08</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">修复一些已知的问题。优化搜索车票、机票时程序的稳定性。</div>
          <div class="footer">版本号：1.0.1</div>
        </div>
      </van-step>

      <van-step class="node">
        <div class="time">
          <div class="MMDD">08-01</div>
          <div class="hhmm">23:00</div>
        </div>
        <div class="contain">
          <div class="detail">发布1.0版本，接入“大唐”机票、火车、酒店。</div>
          <div class="footer">版本号：1.0.0</div>
        </div>
      </van-step>

      <van-step class="last node">
        <div class="time">
          <div class="YYYY">2020年</div>
        </div>
      </van-step>
    </van-steps>
  </div>
</template>

<script>
export default {
  mounted() {
    window.setTitle("版本大事记");
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="less" scoped>
@blue: #1989fa;
.title-hr{
  background-color: @blue;
  height: 3px;
  width: 100%;
}
.memorabilia-cont{
  background-color: #fff;
  h2{
    font-size: 1.4rem;
    text-align: center;
    margin: 0;
    padding: 1rem 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @blue;
    span{
      display: inline-block;
      background-color: #fff;
      white-space: nowrap;
      padding: 0 0.6rem;
    }
  }
}
.active-icon{
  font-size: 3rem;
}
.time{
  position: absolute;
  top: 0.6rem;
  right: calc(100% + 1.7rem);
  width: 60px;
  text-align: right;
  .YYYY{
    color: @blue;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .MMDD{
    font-size: 0.8rem;
    font-weight: 600;
  }
  .hhmm{
    font-size: 0.5rem;
    font-weight: 300;
  }
}
.detail{
  font-size: 14px;
  font-weight: 600;
}
.footer{
  font-size: 10px;
  font-weight: 300;
}
.last{
  padding-bottom: 2rem;
}
</style>
<style lang="less">
.memorabilia-cont{
  background-color: #fff;
  h2{
    margin: 0;
    padding: 1rem 0;
  }
  .van-step__icon--active{
    font-size: 1.3rem;
    background-color: #fff;
  }
  .van-steps--vertical{
    padding-left: 26%;
    padding-right: 1rem;
  }
}
</style>

